import React, { useRef } from "react";

import { content, images, ctaBanner } from "./about.module.scss";

import Layout from "../layouts/Layout";
import AboutBanner from "../partials/AboutBanner";
import BookADemoBanner from "../partials/BookADemoBanner";

import { combine } from "../utils/classNames";
import { useInView } from "../utils/hooks";

const AboutPage = props => {
  const ref = useRef();
  const inView = useInView(ref);

  return (
    <Layout ogDescription="At Brandcrush, we believe retailers and businesses are the new media. Through their owned-media channels, established businesses have the power to offer brands a unique and authentic path to connection with engaged audiences, lowering the cost of customer acquisition and lifting engagement.">
      <AboutBanner />
      <article className={content}>
        <aside>
          <p>
            Through their owned-media channels, established businesses have the power to offer
            brands a unique and authentic path to connection with engaged audiences, lowering the
            cost of customer acquisition and lifting engagement.
          </p>
        </aside>
        <h3>Transforming high-touch activations</h3>
        <p>
          Our end-to-end software solution powers businesses around the world — from grocery &amp;
          D2C retailers, shopping centres, gym chains and universities — to monetize and maximize
          their media selling potential.
        </p>
        <p>
          Through Brandcrush, these businesses are transforming their media opportunities — which
          otherwise tend to live offline in PDF packs and excel sheets — into a digitally bookable
          and scalable media.
        </p>
        <section className={combine(images, inView ? "--in-view" : "")} ref={ref}>
          <img src="/images/about-1@3x.png" alt="" />
          <img src="/images/about-2@3x.png" alt="" />
          <img src="/images/about-3@3x.png" alt="" />
          <img src="/images/about-4@3x.png" alt="" />
          <img src="/images/about-5@3x.png" alt="" />
        </section>
        <h3>Connecting brands with millions of consumers through trusted business</h3>
        <p>
          Changing consumer behaviour, diminishing attention spans and limited top tier media
          positions, means that advertising is not only more costly but also less effective. For
          those looking for new ways to cut through with targeted consumer groups, retail media and
          partnerships offer a golden opportunity to leverage the trusted relationships and high
          engagement these established businesses have with their audience.
        </p>
        <p>
          The world’s leading brands and agencies, including Mondelez, Kraft and Nestle are already
          leveraging Brandcrush’s trusted network of partners to build authentic connections to new
          audiences via on-premise, in home and and online activations.
        </p>
      </article>
      <section className={ctaBanner}>
        <BookADemoBanner copy="Schedule a demo with our team to discover how Brandcrush can work for your business." />
      </section>
    </Layout>
  );
};

export default AboutPage;
