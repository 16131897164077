import React from "react";

import { base, graphic, container } from "./AboutBanner.module.scss";

const AboutGraphic = () => {
  return (
    <div className={graphic}>
      <div className={container}>
        <img
          src="/images/about-graphic/center.png"
          alt=""
          style={{
            right: "18.063rem",
            bottom: "10.813rem",
            width: "8.75rem",
            animation: "none",
          }}
        />
        <img
          src="/images/about-graphic/onsite.png"
          alt=""
          style={{
            right: "18.5rem",
            bottom: "19.875rem",
            width: "3.313rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/email.png"
          alt=""
          style={{
            right: "12.375rem",
            bottom: "23.375rem",
            width: "3.313rem",
            zIndex: 1,
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/offsite.png"
          alt=""
          style={{
            right: "9rem",
            bottom: "20.313rem",
            width: "3.313rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/app.png"
          alt=""
          style={{
            right: "14.625rem",
            bottom: "16.25rem",
            width: "3.313rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/catalogues.png"
          alt=""
          style={{
            right: "5.5rem",
            bottom: "16rem",
            width: "4.375rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/recipes.png"
          alt=""
          style={{
            right: "14rem",
            bottom: "8.375rem",
            width: "3.313rem",
            zIndex: 1,
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/mags.png"
          alt=""
          style={{
            right: "6.813rem",
            bottom: "6.938rem",
            width: "3.313rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/instagram.png"
          alt=""
          style={{
            right: "14.5rem",
            bottom: "3.75rem",
            width: "4.375rem",
            zIndex: 1,
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/tiktok.png"
          alt=""
          style={{
            right: "26.188rem",
            bottom: "8.688rem",
            width: "3.313rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/facebook.png"
          alt=""
          style={{
            right: "27.25rem",
            bottom: "3.75rem",
            width: "4.375rem",
            zIndex: 1,
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/mailers.png"
          alt=""
          style={{
            right: "28.438rem",
            bottom: "11.563rem",
            width: "3.313rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/instore.png"
          alt=""
          style={{
            right: "38.125rem",
            bottom: "7.813rem",
            width: "3.313rem",
            zIndex: 1,
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/inbox.png"
          alt=""
          style={{
            right: "32.813rem",
            bottom: "13.125rem",
            width: "3.313rem",
            zIndex: 1,
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/staff.png"
          alt=""
          style={{
            right: "37.188rem",
            bottom: "13.625rem",
            width: "3.313rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/onpremise.png"
          alt=""
          style={{
            right: "26.813rem",
            bottom: "17.188rem",
            width: "3.313rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/experiences.png"
          alt=""
          style={{
            right: "29rem",
            bottom: "21.188rem",
            width: "4.563rem",
            zIndex: 1,
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/outofstore.png"
          alt=""
          style={{
            right: "23.563rem",
            bottom: "21.75rem",
            width: "3.313rem",
            zIndex: 1,
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/bubble-purple.svg"
          alt=""
          style={{
            right: "8.5rem",
            bottom: "25.813rem",
            width: "1rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/bubble-purple.svg"
          alt=""
          style={{
            right: "11.25rem",
            bottom: "17.875rem",
            width: "1rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/bubble-purple.svg"
          alt=""
          style={{
            right: "8.125rem",
            bottom: "4.625rem",
            width: "1rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/bubble-cerise.svg"
          alt=""
          style={{
            right: "23.563rem",
            bottom: "3.563rem",
            width: "1rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/bubble-cerise.svg"
          alt=""
          style={{
            right: "31.75rem",
            bottom: "9.5rem",
            width: "1rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/bubble-cerise.svg"
          alt=""
          style={{
            right: "35.125rem",
            bottom: "7.375rem",
            width: "1rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/bubble-topaz.svg"
          alt=""
          style={{
            right: "42.688rem",
            bottom: "5.938rem",
            width: "1rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/bubble-topaz.svg"
          alt=""
          style={{
            right: "25.563rem",
            bottom: "20.125rem",
            width: "1rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/bubble-topaz.svg"
          alt=""
          style={{
            right: "38.875rem",
            bottom: "17.875rem",
            width: "1rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/bubble-topaz.svg"
          alt=""
          style={{
            right: "21.875rem",
            bottom: "24.875rem",
            width: "1rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/image-1.png"
          alt=""
          style={{
            right: "13.125rem",
            bottom: "19.625rem",
            width: "4.438rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/image-2.png"
          alt=""
          style={{
            right: "8.625rem",
            bottom: "9.313rem",
            width: "7.688rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/image-3.png"
          alt=""
          style={{
            right: "17.375rem",
            bottom: "3.5rem",
            width: "6.875rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/image-4.png"
          alt=""
          style={{
            right: "31.188rem",
            bottom: "3.438rem",
            width: "4.438rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/image-5.png"
          alt=""
          style={{
            right: "34.625rem",
            bottom: "8.688rem",
            width: "4.438rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
        <img
          src="/images/about-graphic/image-6.png"
          alt=""
          style={{
            right: "30.375rem",
            bottom: "15.375rem",
            width: "7.688rem",
            animationDelay: Math.random() * 5 + "s",
          }}
        />
      </div>
    </div>
  );
};

const AboutBanner = props => {
  return (
    <section className={base}>
      <h1>Unlocking the media power of businesses globally</h1>
      <p>At Brandcrush, we believe retailers and businesses are the new media.</p>
      <AboutGraphic />
    </section>
  );
};

export default AboutBanner;
